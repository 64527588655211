import { animated } from 'react-spring'
import { useMergeRefs } from '@floating-ui/react'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { useElementSize } from '@kaliber/use-element-size'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useParallaxText } from '/machinery/useParallax'
import { routeMap } from '/routeMap'

import { UppercaseHeadingAccentXxl } from '/features/buildingBlocks/UppercaseHeading'
import { HeadingXs, HeadingXxs } from '/features/buildingBlocks/Heading'
import { ImageCoverDynamicScale } from '/features/buildingBlocks/Image'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { refToDataXLink } from '/machinery/tracking/pushToDataLayer'

import mediaStyles from '/cssGlobal/media.css'
import styles from './DevelopmentInformation.css'

export function DevelopmentInformation({ developmentInformation, layoutClassName = undefined }) {
  const { body, footnote, backgroundHeading, backgroundImage, button } = developmentInformation

  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const { ref: sizeRef, size: { height, width } } = useElementSize()
  const { ref: parallaxRef, style: parallaxAnimation } = useParallaxText({
    start: backgroundHeading?.length,
    end: 0
  })

  const ref = useMergeRefs([parallaxRef, sizeRef])
  const aspectRatio = Number(width / height)

  return (
    <div className={cx(styles.component, layoutClassName)} {...{ ref }}>
      <UppercaseHeadingAccentXxl
        value={backgroundHeading ?? []}
        animation={{ y: parallaxAnimation.y.to(x => `calc(${x} * ${isViewportMd ? 25 : 15}dvh)`) }}
        layoutClassName={styles.backgroundTitleLayout}
      />

      <SubGrid
        image={backgroundImage}
        animation={parallaxAnimation}
        layoutClassName={styles.subGridLayout}
        {...{ aspectRatio, body, button, footnote }}
      />
    </div>
  )
}

function SubGrid({ image, aspectRatio, animation, body, button, footnote, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentSubGrid, layoutClassName)}>
      {image && (
        <Background
          aspectRatio={aspectRatio || 1 / 1}
          layoutClassName={styles.backgroundLayout}
          {...{ image, animation }}
        />
      )}

      <Content layoutClassName={styles.contentLayout} {...{ body, button, footnote }} />
    </div>
  )
}

function Background({ image, animation, aspectRatio, layoutClassName = undefined }) {
  return (
    <animated.div
      className={cx(styles.componentBackground, layoutClassName)}
      style={{ '--scroll-y': animation.y, '--scroll-value': animation.value }}
    >
      <div className={styles.transformWrapper}>
        <ImageCoverDynamicScale
          layoutClassName={styles.imageLayout}
          {...{ image, aspectRatio }}
        />
      </div>
    </animated.div>
  )
}

function Content({ body, button, footnote, layoutClassName = undefined }) {
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false

  const Heading = isMobile
    ? HeadingXxs
    : HeadingXs

  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <div className={styles.innerContentBlock}>
        <Heading h='3' title={body} layoutClassName={styles.growthExplanationLayout} />
        <p className={styles.drive}>{footnote}</p>

        {button?.ref && (
          <div className={styles.buttonElement}>
            <ButtonGhost
              dataX={refToDataXLink(button.ref)}
              href={determineDocumentPathSyncWithParams({ document: button.ref, routeMap, params: button.params })}
            >
              {button.label}
            </ButtonGhost>
          </div>
        )}
      </div>
    </div>
  )
}
